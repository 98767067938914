<template>
  <div v-if="files && files.length>0">
    <h4 class="text-primary mb-2" v-if="title"><fa-icon :icon="['far','paperclip']" class="mr-1" />{{title}}</h4>
    <view-upload v-if="viewActive" :src="viewSrc" />
    <b-avatar-group :size="size">
      <b-avatar v-for="file of files" :key="file.url" v-b-tooltip.hover class="pull-up" :title="file.details" 
        :src="fileUrl(file.url)" :link="file.type==='image'?false:true" :href="fileUrl(file.url)" target="_blank"
        :button="file.type==='image'?true:false" @click.stop="showFile(file)" >
        <fa-icon v-if="file.type!=='image'" icon="file-download" :size="iconSize"/>
      </b-avatar>
    </b-avatar-group>
  </div>
</template>

<script>
import '../file-upload/fileUpload.scss'
import { ref } from '@vue/composition-api'
import viewUpload from './viewUpload'
import { fileUrl } from '@core/utils/filter'
import { BAvatarGroup, BAvatar, VBTooltip } from 'bootstrap-vue'

export default {
  name: 'file-gallery',
  components:{
    viewUpload,
    BAvatarGroup, BAvatar
  },
  props:{
    files : {
      type: Array,
      default: ()=>[]
    },
    title: {
      type: String,
      default:''
    },
    size: {
      type: String,
      default: '50px'
    },
    iconSize: {
      type: String,
      default: '2x'
    }
  },
  setup(props){
    const viewActive = ref(false)
    const viewSrc = ref(null)

    const showFile = (fileObj)=>{
      if(fileObj.type.toLowerCase() === 'image'){
        viewSrc.value = fileUrl(fileObj.url);
        viewActive.value = true
      }
    }

    return {
      fileUrl,
      showFile,

      viewActive, viewSrc
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
}
</script>

<style>

</style>