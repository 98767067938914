import { ref } from '@vue/composition-api'
import store from '@/store'
import $global from '@/globalVariables'
import variantStoreModule from './variantStoreModule'
if (!store.hasModule('variant')) store.registerModule('variant', variantStoreModule)

export default function useVariantList() {
  const loading = ref(false)
  const emptyVariant = ()=> ({
    isActive: true,
    currency:$global.default_currency,
    size:[0,0,0], 
    volume:0,
    names:$global.locales.map(locale=>({locale, name:null})), 
    price:0, files:[], html:''
  })

  const variantData =  ref(emptyVariant())
  const resetVariant = () => variantData.value = emptyVariant()

  const fetchVariant = (id, callback) => {
    if (!id) return
    store.dispatch('variant/fetchVariant', id).then(response => {
      variantData.value = response.variant
      if(callback) callback(response)
    }).catch(() => productData.value = null )
  }

  const deleteVariant = variant => {
    return new Promise(res=>{
      if (!variant._id) res(true)
      store.dispatch('variant/deleteVariant', variant._id).then(()=>res(true))
    })
  }

  return {
    loading,
    variantData,
    fetchVariant,
    resetVariant,
    deleteVariant
  }
}
