<template>
  <component :is="isPage?'div':'BSidebar'" id="sale-view" right sidebar-class="sidebar-lg" shadow backdrop :width="sidebarWidth" header-class="p-0" bg-variant="white"
    :visible="isSidebarActive" @hidden="close()" class="rounded-lg overflow-hidden shadow-sm">
    <template v-slot:header>
      <div class="w-100">
        <div class="w-100 d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 bg-white border-bottom shadow-sm z-index-10">
          <div v-if="saleData" class="flex gap-3 align-items-center text-primary">
            <fa-icon :icon="['fad', $t('member.customer.icon')]" size="2x"/>
            <div class="flex flex-column">
              <span class="text-capitalize">{{$t('supply-chain.sale._')}}</span>
              <span class="m-0 h5">{{saleData.orderNo}}</span>
            </div>
            <q-btn flat padding="5px" :to="{name: 'sale-view', params:{saleId: saleData._id}}"><fa-icon icon="expand"/></q-btn>
          </div>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="close"/>
        </div>
        <q-tabs v-model="tab" class="bg-light-secondary inner-shadow" active-color="primary" indicator-color="primary" align="justify" narrow-indicator>
          <q-tab name="details" icon="fas fa-info-circle" :label="$t('form.details') | capitalize" class="pt-50 " content-class="gap-50 mb-25"/>
          <q-tab name="track" icon="fas fa-truck" :label="$t('supply-chain.sale.track') | capitalize" class="pt-50" content-class="gap-50 mb-25"/>
          <q-tab name="journal" icon="fas fa-money-check-edit-alt" :label="$t('finance.journal') | capitalize" class="pt-50" content-class="gap-50 mb-25"/>
          <q-tab name="reward" icon="fas fa-trophy" :label="$t('member.customer.reward') | capitalize" class="pt-50" content-class="gap-50 mb-25"/>
        </q-tabs>
      </div>
    </template>

    <q-tabs v-if="isPage" v-model="tab" class="bg-light-secondary" active-color="primary" indicator-color="primary" align="justify" narrow-indicator>
      <q-tab name="details" icon="fas fa-info-circle" :label="$t('form.details') | capitalize" class="pt-50 " content-class="gap-50 mb-25"/>
      <q-tab name="track" icon="fas fa-truck" :label="$t('supply-chain.sale.track') | capitalize" class="pt-50" content-class="gap-50 mb-25"/>
      <q-tab name="journal" icon="fas fa-money-check-edit-alt" :label="$t('finance.journal') | capitalize" class="pt-50" content-class="gap-50 mb-25"/>
      <q-tab name="reward" icon="fas fa-trophy" :label="$t('member.customer.reward') | capitalize" class="pt-50" content-class="gap-50 mb-25"/>
    </q-tabs>    
    <q-tab-panels v-model="tab" value="order" animated transition-prev="jump-left" transition-next="jump-right" class="h-100 bg-white">
      <q-tab-panel name="details" class="p-0">
        <div v-if="saleData" class="pb-2">
          <Carousel :files="saleData.files" />
          <div class="shadow-top-sm px-2 pt-2 flex gap-2">
            <!-- Order number -->
            <div class="flex-size-4 flex-even d-flex gap-2">
              <fa-icon :icon="['fad','shopping-cart']" fixed-width class="text-primary text-size-150 mt-50"/>
              <div>
                <label class="text-primary d-block">{{$t('form.order_number')|capitalize}}</label>
                <div>{{saleData.orderNo?saleData.orderNo.toUpperCase():''}}</div>
              </div>
            </div>

            <!-- Sale -->
            <div class="flex-size-4 flex-even d-flex gap-2">
              <fa-icon :icon="['fad','user']" fixed-width class="text-primary text-size-150 mt-50"/>
              <div v-if="saleData.customer">
                <label class="text-primary d-block text-capitalize">{{$t('form.name')}}</label>
                <span>{{saleData.customer.name?saleData.customer.name.toUpperCase():''}}</span>
                <q-chip size="sm" color="light-primary" class="m-0 ml-50">{{saleData.customer.customerNo}}</q-chip>
              </div>
            </div>

            <!-- Tracking number -->
            <div class="flex-size-4 flex-even d-flex gap-2">
              <fa-icon :icon="['fad','shipping-fast']" fixed-width class="text-primary text-size-150 mt-50"/>
              <div>
                <label class="text-primary d-block text-capitalize">{{$t('form.track_number')}}</label>
                <span>{{saleData.trackNo?saleData.trackNo.toUpperCase():''}}</span>
              </div>
            </div>
            
            <!-- Date -->
            <div class="flex-size-4 flex-even d-flex flex-even gap-2">
              <fa-icon :icon="['fad','calendar-alt']" fixed-width class="text-primary text-size-150 mt-50"/>
              <div>
                <label class="text-primary d-block text-capitalize">{{$t('form.date')}}</label>
                <span class="text-wrap">{{saleData.orderedAt | dateString}}</span>
              </div>
            </div>

            <!-- Tags -->
            <div class="flex-size-4 flex-even d-flex gap-2">
              <fa-icon :icon="['fad','tags']" fixed-width class="text-primary text-size-150 mt-50"/>
              <div>
                <label class="text-primary d-block text-capitalize">{{$t('form.tags')}}</label>
                <span class="d-flex flex-wrap gap-1">
                  <q-chip v-for="(tag, index) in saleData.tags" color="light-primary" :key="tag.index" dense >{{tag}}</q-chip>
                  <q-btn flat dense @click.stop="updateTags({data:saleData.tags, label:$t('form.tags'), icon: ['fad','tags']})"><fa-icon class="text-danger mx-n1" icon="pen"/></q-btn>
                </span>
              </div>
            </div>
          </div>

          <div class="px-2 pt-2">
            <BTable class="position-relative rounded-lg shadow mb-0 text-center" responsive hover primary-key="_id" :items="saleData.variants" :fields="[
              {key:'name',label:$t('form.name')},
              {key:'sku',label:$t('form.sku')}, 
              {key:'quantity',label:$t('form.quantity')}, 
              {key:'amount',label:$t('form.amount')}]">
              <template #cell(name)="{ item }">
                <div class="d-flex gap-2 align-items-center">
                  <Avatar :url="item.files" />
                  <div v-if="item.names" class="text-nowrap text-left">
                    <div class="m-0 h5" :class="item.isInventory?'text-primary':'text-danger'">{{item.productNames | name}}</div>
                    <div>{{item.names | name }}</div>
                  </div>
                </div>
              </template>

              <!-- Amount -->
              <template #cell(amount)="{ item }">
                <div class="text-nowrap text-center" :class="item.amount<0?'text-danger':'text-primary'">
                  {{item.amount<0?'-':''}} {{Math.abs(item.amount||0) | price($t(`currency.${item.currency}.sign`))}}
                </div>
              </template>
            </BTable>
          </div>

          <!-- Packing -->
          <div class="px-2 pt-2">
            <BTable v-if="saleData.packing" class="position-relative rounded-lg shadow mb-0 text-center" responsive show-empty hover primary-key="_id"
              :empty-text="$t('table.noResult')" :items="saleData.packing.packages" :fields="[
                {key:'icon',label:''},
                {key:'size',label:`${$t('form.size')} (${$global.default_size})`},
                {key:'volume',label:$t('form.volume')}, 
                {key:'weight',label:$t('form.weight')}, 
                {key:'quantity',label:$t('form.quantity')}]">
              <template #thead-top>
                <tr><td colspan="10" class="bg-light-primary text-left p-1">
                  <div class="h5 mb-0 text-capitalize text-primary">
                    <fa-icon :icon="['fad','box-full']" class="mr-50" />
                    {{$t('supply-chain.packing._')}}</div>
                </td></tr>
              </template>
              <template #cell(icon)="{ item }">
                <q-avatar><fa-icon :icon="['fad',item.quantity>1?'boxes':'box']" class="text-warning" /></q-avatar>
              </template>
              <template #cell(size)="{ item }">
                <span v-if="item.size && item.size.length>0 && item.volume" class="text-nowrap">{{item.size[0]}} x {{item.size[1]}} x {{item.size[2]}}</span>
              </template>
              <template #cell(volume)="{ item }">
                <template v-if="item.volume">
                  <fa-icon icon="cube" class="text-primary mr-50"/>
                  {{item.volume}} {{$global.default_volume}}
                </template>
              </template>
              <template #cell(weight)="{ item }">
                <template v-if="item.weight">
                  <fa-icon icon="weight-hanging" class="text-primary mr-50"/>
                    {{item.weight}} {{$global.default_weight}}
                </template>
              </template>
              <template #cell(quantity)="{ item }">
                <fa-icon icon="times" class="text-primary mr-50"/>{{item.quantity}}
              </template>
              <template #custom-foot>
                <tr>
                  <th colspan="2"></th>
                  <th>{{saleData.packing.volume}} {{$global.default_volume}}</th>
                  <th>{{saleData.packing.weight}} {{$global.default_weight}}</th>
                  <th>{{saleData.packing.quantity}}</th>
                </tr>
              </template>
            </BTable>
          </div>

          <!-- Address -->
          <div v-if="saleData.address" class="mx-2 mt-2 shadow rounded-lg">
            <div class="card-header bg-light-primary p-1 h5">
              <fa-icon icon="route" class="mr-50" fixed-width/>
              {{$t('action.address')|capitalize}}
            </div>
            <div class="form px-1 py-2 d-flex flex-column flex-sm-row gap-2 flex-sm-wrap">
              <!-- 联系人 -->
              <div class="flex-size-4 flex-grow-1 d-flex gap-2">
                <fa-icon :icon="['fad','user']" fixed-width class="text-primary text-size-150"/>
                <div>
                  <div class="text-primary d-block">{{$t('form.name')|capitalize}}</div>
                  <span class="text-wrap">{{saleData.address.name}}</span>
                </div>
              </div>
              <!-- 电话 -->
              <div class="flex-size-4 flex-grow-1 d-flex gap-2">
                <fa-icon :icon="['fad','mobile-android-alt']" fixed-width class="text-primary text-size-150"/>
                <div>
                  <div class="text-primary d-block">{{$t('form.phone')|capitalize}}</div>
                  <span class="text-wrap">+{{saleData.address.country}} {{saleData.address.phone}}</span>
                </div>
              </div>
              <!-- 邮箱 -->
              <div class="flex-size-4 flex-grow-1 d-flex gap-2">
                <fa-icon :icon="['fad','at']" fixed-width class="text-primary text-size-150"/>
                <div>
                  <div class="text-primary d-block">{{$t('form.email')|capitalize}}</div>
                  <span class="text-wrap">{{saleData.address.email}}</span>
                </div>
              </div>
              <!-- 邮编 & City -->
              <div class="flex-size-4 flex-grow-1 d-flex gap-2">
                <fa-icon :icon="['fad','mailbox']" fixed-width class="text-primary text-size-150"/>
                <div>
                  <div class="text-primary d-block">{{$t('form.zip')|capitalize}}</div>
                  <span class="text-wrap">{{saleData.address.zip}}</span>
                </div>
              </div>
              <!-- 地址 -->
              <div class="flex-size-4 flex-grow-1 d-flex gap-2">
                <fa-icon :icon="['fad','map-marker-alt']" fixed-width class="text-primary text-size-150"/>
                <div>
                  <div class="text-primary d-block">{{$t('action.address')|capitalize}}</div>
                  <span class="text-wrap">{{saleData.address.address}}</span>
                </div>
              </div>
            </div>
          </div>
          <Logs model="sale" :data="saleData.logs" :modelId="saleData._id" :quantity="2" :addable="!saleData.deleted" :title="$t('log._')" class="m-2 mb-0"/>
          <FieldDialog ref="fieldDialogRef" />
        </div>
      </q-tab-panel>

      <q-tab-panel name="track" class="p-0 bg-light">
        <Tracking ref="trackRef" :data="saleData.tracks" :funcAdd="addTrack" :funcRemove="removeTrack" update />
      </q-tab-panel>

      <q-tab-panel name="journal" class="p-0">
        <div v-if="saleData" class="d-flex flex-column gap-3 p-2">
          <BTable v-for="(entry,i) in saleData.entries" :key="i" ref="refOrderListTable" class="journal position-relative rounded-lg shadow mb-0" 
            bordered borderless responsive show-empty hover primary-key="_id" thead-class="text-left" sortBy="account.code"
            :items="entry.transactions" :fields="[
              {key:'details',label:$t('form.details')},
              {key:'code',label:$t('finance.code')},
              {key:'dr',label:$t('finance.dr')}, 
              {key:'cr',label:$t('finance.cr')}
            ]">
            <template #empty>
              <fa-icon class="my-3 text-secondary" :icon="['fad','cauldron']" size="4x" />
              <h5>{{$t("table.noResult")}}</h5>
            </template>
            <!-- details -->
            <template #cell(details)="{ item }">
              <div class="d-flex gap-2 align-items-center">
                <q-chip dense class="ml-1 pl-0">
                  <q-chip dense class="mr-50" :color="`${getColor('account', item.account.class)}`" text-color="white">
                    <small class="text-capitalize">{{item.account.class}}</small>
                  </q-chip>
                  <span class="text-truncate">
                    {{item.account.names | name}}
                    <q-tooltip label="Scale Menu" anchor="top middle" :offset="[0, 30]">{{item.account.name}}</q-tooltip>
                  </span>
                </q-chip>
              </div>
            </template>

            <!-- Code -->
            <template #cell(code)="{ item }">
              <div class="ml-1 pl-0">
                {{item.account.code}}
              </div>
            </template>

            <!-- DR -->
            <template #cell(dr)="{ item }">
              <div v-if="item.account.drcr==='dr'" class="text-nowrap d-flex align-items-center" :class="item.amount<0?'text-danger':'text-primary'">
                <span style="min-width:10px" class="d-inline-block">{{item.amount<0?'-':''}}</span>{{Math.abs(item.amount||0) | price($t(`currency.${item.currency}.sign`))}}
              </div>
            </template>

            <!-- CR -->
            <template #cell(cr)="{ item }">
              <div v-if="item.account.drcr==='cr'" class="text-nowrap d-flex align-items-center" :class="item.amount<0?'text-danger':'text-primary'">
                <span style="min-width:10px" class="d-inline-block">{{item.amount<0?'-':''}}</span>{{Math.abs(item.amount||0) | price($t(`currency.${item.currency}.sign`))}}
              </div>
            </template>

            <!-- Footer -->
            <template #custom-foot="{items}">
              <tr class="font-weight-normal h5 bg-light-secondary">
                <td colspan="10" class="py-1 text-capitalize ">
                  <div class="d-flex justify-content-between align-items-center text-dark">
                    <span class="m-0 font-weight-normal ">{{entry.details}}</span>
                    <small class="font-weight-bold">{{entry.date | date}}</small>
                  </div>
                </td>
              </tr>
            </template>
          </BTable>
        </div>
      </q-tab-panel>

      <q-tab-panel name="reward" class="p-0">
        <!-- details -->
        <div v-if="saleData" class="d-flex flex-column gap-3 p-2">
          <BTable ref="refOrderListTable" class="reward position-relative rounded-lg shadow mb-0" 
            striped responsive show-empty hover primary-key="_id" thead-class="text-center" sortBy="levelDif" sort-desc
            :items="saleData.customerTrans" :fields="[
              {key:'levelDif', label:capitalize($t('mlm.level')), sortable: true},
              {key:'customer', label:capitalize($t('member.customer._')), stickyColumn: true, isRowHeader: true},
              {key:'balance', sortable: true},
              {key:'credit', sortable: true},
              {key:'pension', sortable: true},
              {key:'rewardChild',label:capitalize($t('mlm.child'))},
              {key:'rewardOrg',label:capitalize($t('mlm.org'))}, 
              {key:'rewardGroup',label:capitalize($t('mlm.group'))}, 
              {key:'team1',label:capitalize($t('mlm.team')+' 1')},
              {key:'team2',label:capitalize($t('mlm.team')+' 2')}
            ]">
            <template #empty>
              <div class="text-center">
                <fa-icon class="my-3 text-secondary" :icon="['fad','cauldron']" size="4x" />
                <h5>{{$t("table.noResult")}}</h5>
              </div>
            </template>

            <template #thead-top="data">
              <BTr>
                <BTh colspan="2"></BTh>
                <BTh colspan="3" class="bg-light-primary">{{$t('form.summary')}}</BTh>
                <th colspan="5">{{$t('form.breakdown')}}</th>
              </BTr>
            </template>

            <template #head(balance)>
              <fa-icon icon="dollar-sign" class="text-primary mr-50"/>
              {{capitalize($t('mlm.balance'))}}
            </template>
            
            <template #head(credit)>
              <fa-icon icon="coins" class="text-warning mr-50"/> {{capitalize($t('mlm.credit'))}}
            </template>

            <template #head(pension)>
              <fa-icon icon="piggy-bank" class="text-success mr-50"/> {{capitalize($t('mlm.pension'))}}
            </template>

            <template #cell(levelDif)="{ value, item }">
              <div class="text-center">
                <template v-if="value===0 && item.type==='upgrade'">
                  <fa-icon :icon="['fad','user']" class="text-primary"/>
                  <fa-icon v-if="item.type==='upgrade'" :icon="['fad','angle-double-up']" class="ml-50 text-danger"/>
                </template>
                <fa-icon v-else-if="item.type==='payment'" :icon="['fad','shopping-cart']" class="ml-50 text-danger"/>
                <span v-else>{{value}}</span>
              </div>
            </template>

            <!-- 上线 -->
            <template #cell(customer)="{ item }">
              <q-chip v-if="item.grade && item.grade.names" size="sm" :color="item.grade.color" text-color="white">{{item.grade.names | name}}</q-chip>
              <div class="d-flex gap-1 align-items-center">
                {{item.customer.name}}
                <q-btn flat padding="none" v-clipboard:copy="item.customer.customerNo" v-clipboard:success="()=>Toast.fire({icon:'success', title:capitalize($t('form.copied'))})">
                  <fa-icon :icon="['far','clone']" class="text-primary"/>
                  <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">{{item.customer.customerNo}}</q-tooltip>
                </q-btn>
              </div>
            </template>
            <!-- Balance -->
            <template #cell(balance)="{ value }">
              <div v-if="value && value.c" class="d-flex flex-column">
                <q-chip dense color="light-primary">
                  <q-avatar color="primary" text-color="white"><fa-icon icon="dollar-sign" /></q-avatar>
                  <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value.c<0}">
                    {{value.c<0?'-':'+'}} {{Math.abs(value.c||0) | price}}
                  </span>
                </q-chip>
                <q-chip dense color="light-secondary">
                  {{value.b||0}}<fa-icon icon="chevron-right" class="mx-50"/>{{value.a||0}}</span>
                </q-chip>
              </div>
            </template>
            <!-- Credit -->
            <template #cell(credit)="{ value }">
              <div v-if="value && value.c" class="d-flex flex-column">
                <q-chip dense color="light-warning" class="justify-content-between text-right">
                  <q-avatar color="warning" text-color="white"><fa-icon icon="coins" /></q-avatar>
                  <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value.c<0}">
                    {{value.c<0?'-':''}} {{Math.abs(value.c||0) | price}}
                  </span>
                </q-chip>
                <q-chip dense color="light-secondary">
                  {{value.b||0}}<fa-icon icon="chevron-right" class="mx-50"/>{{value.a||0}}</span>
                </q-chip>
              </div>
            </template>
            <!-- Pension -->
            <template #cell(pension)="{ value }">
              <div v-if="value && value.c" class="d-flex flex-column">
                <q-chip dense color="light-success">
                  <q-avatar color="success" text-color="white"><fa-icon icon="piggy-bank" /></q-avatar>
                  <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value.c<0}">
                    {{value.c<0?'-':''}} {{Math.abs(value.c||0) | price}}
                  </span>
                </q-chip>
                  <q-chip dense color="light-secondary">
                  {{value.b||0}}<fa-icon icon="chevron-right" class="mx-50"/>{{value.a||0}}</span>
                </q-chip>
              </div>
            </template>
            <!-- child -->
            <template #cell(rewardChild)="{ value }">
              <q-chip v-if="value" dense>
                <q-avatar color="primary" text-color="white"><fa-icon icon="dollar-sign" /></q-avatar>
                <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value<0}">
                  {{value<0?'-':''}} {{Math.abs(value||0) | price}}
                </span>
              </q-chip>
            </template>
            <!-- org -->
            <template #cell(rewardOrg)="{ value }">
              <q-chip v-if="value" dense>
                <q-avatar color="primary" text-color="white"><fa-icon icon="dollar-sign" /></q-avatar>
                <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value<0}">
                  {{value<0?'-':''}} {{Math.abs(value||0) | price}}
                </span>
              </q-chip>
            </template>
            <!-- Group -->
            <template #cell(rewardGroup)="{ value }">
              <q-chip v-if="value" dense>
                <q-avatar color="primary" text-color="white"><fa-icon icon="dollar-sign" /></q-avatar>
                <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value<0}">
                  {{value<0?'-':''}} {{Math.abs(value||0) | price}}
                </span>
              </q-chip>
            </template>
            <!-- Team 1 -->
            <template #cell(team1)="{ item }">
              <div class="d-flex flex-column justify-content-around align-items-start">
                <q-chip v-if="item.rewardTeam1" dense>
                  <q-avatar color="primary" text-color="white"><fa-icon icon="dollar-sign" /></q-avatar>
                  <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value<0}">
                    {{item.rewardTeam1<0?'-':''}} {{Math.abs(item.rewardTeam1||0) | price}}
                  </span>
                </q-chip>
                <q-chip v-if="item.creditTeam1" dense>
                  <q-avatar color="orange" text-color="white"><fa-icon icon="coins" /></q-avatar>
                  <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value<0}">
                    {{item.creditTeam1<0?'-':''}} {{Math.abs(item.creditTeam1||0) | price}}
                  </span>
                </q-chip>
              </div>
            </template>
            <!-- Team 2 -->
            <template #cell(team2)="{ item }">
              <q-chip v-if="item.rewardTeam2" dense>
                <q-avatar color="primary" text-color="white"><fa-icon icon="dollar-sign" /></q-avatar>
                <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value<0}">
                  {{item.rewardTeam2<0?'-':''}} {{Math.abs(item.rewardTeam2||0) | price}}
                </span>
              </q-chip>
              <q-chip v-if="item.creditTeam2" dense>
                <q-avatar color="orange" text-color="white"><fa-icon icon="coins" /></q-avatar>
                <span class="text-nowrap font-weight-bold mx-25" :class="{'text-danger':value<0}">
                  {{item.creditTeam2<0?'-':''}} {{Math.abs(item.creditTeam2||0) | price}}
                </span>
              </q-chip>
            </template>
          </BTable>
        </div>
      </q-tab-panel>
    </q-tab-panels>
    <q-inner-loading :showing="loading">
      <q-spinner-gears size="50px" color="primary" />
    </q-inner-loading>
  </component>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { BSidebar, BTable, BTr, BTh } from 'bootstrap-vue'
import { Dialog } from '@core/utils/other'
import { date, dateString, capitalize, price, getColor, fileUrl, i18n, name } from '@core/utils/filter'
import useSale from './useSale'
import FileGallery from '@core/components/file-gallery/FileGallery'
import Carousel from '@core/components/Carousel'
import Avatar from '@core/components/Avatar'
import Logs from '@core/components/logs/Logs'
import AccountSidebarEntry from '@/views/finance/account/components/sidebar/AccountSidebarEntry'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import FieldDialog from '@core/components/FieldDialog'
import Tracking from '@core/components/Tracking'

export default {
  name: 'sale-view',
  components: {
    BSidebar, BTable, BTr, BTh,
    FileGallery, Logs, Carousel, Avatar,
    AccountSidebarEntry,
    AppCollapse, AppCollapseItem,
    FieldDialog,
    Tracking
  },
  props:{
    isPage: Boolean
  },
  filters: {
    date, dateString, capitalize, price, name
  },
  setup(props){
    const { route, router } = useRouter()
    const {
      loading,
      saleData,
      fetchSale,
      approveSale,
    } = useSale()

    // Page view
    if (props.isPage) {
      if (route.value.params.saleId) {
        loading.value = true
        fetchSale(route.value.params.saleId, ()=>loading.value = false)
      } else {
        router.go(-1)
      }
    }
    
    // Sidebar view
    const isSidebarActive = ref(false)
    let isUpdated = false
    let closeAction = () => isSidebarActive.value = false
    const close = () => closeAction()
    const open = async id => {
      return new Promise(res=>{
        if(!id) return res(false)
        loading.value = true
        isSidebarActive.value = true
        fetchSale(id, ()=> {
          loading.value = false
          closeAction = () => {
            res(isUpdated)
            isSidebarActive.value = false
          }
        })
      })
    }

    // Tabs
    const tab = ref('details')
    const sidebarWidth = ref('800px') 
    watch(tab, val=>{
      if (val==='details') sidebarWidth.value = "800px"
      if (val==='track') sidebarWidth.value = '800px'
      if (val==='journal') sidebarWidth.value = '800px'
      if (val==='reward') sidebarWidth.value = '1200px'
    })

    // Update tags
    const fieldDialogRef = ref(null)
    const updateTags = async () =>{
      const newTags = await fieldDialogRef.value.openDialog({data:saleData.value.tags, label:i18n('form.tags'), icon: ['fad','tags']})
      if (newTags === false) return
      loading.value = true
      store.dispatch('sale/updateTags', {saleId: saleData.value._id, tags:newTags}).then(()=>fetchSale(saleData.value._id, ()=>{
        isUpdated = true
        loading.value = false
      })).catch(e=>{
        loading.value = false
        Dialog(false).fire({
          icon: 'error',
          title: e.message
        })
      })
    }

    // Track
    const addTrack = track => store.dispatch('sale/addTrack', {saleId: saleData.value._id, track}).then(r=> saleData.value.tracks = r.tracks)
    const removeTrack = trackId => store.dispatch('sale/removeTrack', {saleId: saleData.value._id, trackId}).then(r=> saleData.value.tracks = r.tracks)

    return {
      loading,
      capitalize, getColor, fileUrl,
      saleData,

      // Sidebar
      isSidebarActive,
      sidebarWidth,
      open,
      close,

      // Tab
      tab,

      // Actions
      approveSale,

      // Tags
      fieldDialogRef,
      updateTags,
      
      // track
      addTrack,
      removeTrack
    }
  }
}
</script>

<style lang="scss">
html {
  overflow: hidden;
}
#sale-view {
  transition: all 300ms;
  
  //Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
  .b-sidebar-body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .text-size-150 {
    font-size: 1.5rem
  }
  .z-index-10 {
    z-index: 10
  }

  .form {
    span:empty:before {
      content: "\200b"; // unicode zero width space character
    }
  }

  .journal {
    table {
      td {
        padding: 0.25rem 1rem;
      }
      tr:first-child {
        td {
          padding-top: 0.5rem;
        }
      }
      tr:last-child {
        td {
          padding-bottom: 0.5rem;
        }
      }
    }
  }

  .reward {
    .q-chip__content {
      justify-content: space-between;
    }
    table {
      th {
        padding: 0.72rem 1.25rem;
      }

      td:nth-child(3){
        border-left: rgba(var(--secondary),1) solid 1px;
      }

      td:nth-child(5){
        border-right: rgba(var(--secondary),1) solid 1px;
      }
    }
  }

  
}
</style>