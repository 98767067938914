<template>
  <q-avatar :size="size" :color="color">
    <q-img v-if="fileUrl(url)" ratio="1" :src="fileUrl(url)"/>
    <fa-icon v-if="!fileUrl(url)" :icon="defaultIcon" :class="iconClass"/>
  </q-avatar>
</template>

<script>
import { fileUrl } from '@core/utils/filter'
export default {
  name: 'avatar',
  props: {
    url: {
      type: [String, Array],
    },
    size: {
      type: String,
      default: 'lg'
    },
    color: {
      type: String,
      default: 'light-secondary'
    },
    defaultIcon: {
      type: [String, Array],
      default: ()=>['far','tshirt']
    },
    iconClass: String
  },
  setup(){
    return {
      fileUrl
    }
  }
}
</script>