<template>
  <b-card no-body :class="{'open': visible}" class="rounded-lg" @mouseenter="collapseOpen" @mouseleave="collapseClose">
    <b-card-header role="tab" data-toggle="collapse"
      class="bg-light-primary header"
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      @click="updateVisible(!visible)">
      <slot name="header">
        <span class="lead collapse-title">{{ title }}</span>
      </slot>
    </b-card-header>

    <slot name="show" />

    <b-collapse :id="collapseItemID" v-model="visible" :accordion="accordion" role="tabpanel" v-if="!nobody">
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>

    <template v-if="footer">
      <div class="text-center border-top p-1 text-primary" v-if="!visible" @click="updateVisible(true)">
        <fa-icon :icon="['fad', 'angle-double-down']" size="2x"  class="ml-1" />
      </div>
      <div class="text-center border-top p-1 text-danger" v-if="visible" @click="updateVisible(false)">
        <fa-icon :icon="['fad', 'angle-double-up']" size="2x" class="ml-1" />
      </div>
    </template>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    headerClass: {
      type: String
    },
    nobody: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      //visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      footer: this.$parent.footer
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    //this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      //this.visible = val
      this.$emit('update:visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>
<style lang="scss" scoped>
.header{
  border-top-left-radius: 0.6rem !important;
  border-top-right-radius: 0.6rem
}
</style>
