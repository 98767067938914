<template>
  <div id="status-timeline" v-if="model" class="bg-white shadow-sm rounded-lg">
    <AppCollapse footer>
      <AppCollapseItem class="shadow-none" title nobody :visible.sync="visible" headerClass="bg-light-primary">
        <!-- Header -->
        <template #header>
          <span class="h5 m-0 text-primary">
            <fa-icon :icon="['fas', $t('log.icon')]" class="mr-50" fixed-width />
            {{title}}
          </span>
        </template>

        <template #show>
          <div class="px-1">
            <AppTimeline class="mt-1 d-flex flex-column gap-2">
              <transition-group name="fade">
                <AppTimelineItem key="0" v-if="addable && modelId && $can('update', capitalize(model))" fa-icon="plus" variant="success" class="pb-2">
                  <q-input type="textarea" filled autogrow v-model="details" placeholder="Add details" class="flex-grow-1 m-0">
                    <template v-slot:append>
                      <q-btn push @click="addLog" color="primary" :loading="loading" class="mx-50"><fa-icon icon="plus" class="my-25" /></q-btn>
                    </template>
                  </q-input>
                </AppTimelineItem>
                
                <AppTimelineItem v-for="obj in filteredStatusArray" :key="obj._id" :variant="obj.status?$t(`log.status.${obj.status}.color`):''" class="pb-2"
                  :no-icon="!obj.status" :fa-icon="obj.status?$t(`log.status.${obj.status}.icon`):''">
                  <div :class="obj.status?'':'rounded border-primary ml-n5 mr-n50 bg-white shadow'" class="position-relative z-index-0">
                    <div class="d-flex align-items-center flex-row justify-content-between gap-2" :class="obj.status?'':'bg-light-primary px-75 py-50'">
                      <div>
                        <h6 v-if="obj.status" class="text-capitalize">{{$t(`log.status.${obj.status}._`)}}<q-badge v-if="isToday(obj.createdAt)" color="primary" class="ml-1 text-capitalize">{{$t('log.today')}}</q-badge></h6>
                        <h6 class="mb-25 text-break text-primary" style="white-space: break-spaces">{{obj.details}}</h6>
                        <small class="text-secondary text-break">{{obj.createdAt | date(true)}}</small>
                      </div>
                      <div class="d-flex align-items-center">
                        <Avatar :url="obj.user.files" defaultIcon="user"/>
                        <span v-if="!obj.status && $can('update', capitalize(model))" class="p-1" @click.stop="deleteLog(obj._id)"><fa-icon class="text-danger" icon="times"/></span>
                      </div>
                    </div>
                  </div>
                </AppTimelineItem>
              </transition-group>
            </AppTimeline>
          </div>
        </template>

      </AppCollapseItem>
    </AppCollapse>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import logStoreModule from './logStoreModule'
import { isToday } from '@core/utils/utils'
import { date, fileUrl, capitalize, i18n } from '@core/utils/filter'
import { VBTooltip} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Avatar from '@core/components/Avatar'
// import Ripple from 'vue-ripple-directive'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'logs',
  components:{
    AppTimeline, AppTimelineItem,
    AppCollapse, AppCollapseItem,
    Avatar
  },
  props: {
    title: String,
    data:{
      type: Array,
      default:()=>[]
    },
    model: {
      type: String,
      validator: val => ['inventory', 'purchase', 'sale', 'shipment','entry'].includes(val)
    },
    addable: {
      type: Boolean,
      default: false
    },
    modelId: String,
    quantity: {
      type: Number,
      default: 0
    },
    open:{
      type: Boolean,
      default: false
    }
  },
  directives: { 'b-tooltip': VBTooltip },
  filters:{ date },
  setup (props){
    const STORE_MODULE_NAME = 'log'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, logStoreModule)

    const details = ref('')
    const toast = useToast()
    const loading = ref(false)
    const logsData = ref([])
    const visible = ref(props.open)
    const filteredStatusArray = ref([])

    watch(()=>props.modelId, ()=>{
      fetchLogs()
    })

    watch([logsData, visible], ()=>{
      if (!logsData.value || logsData.value.length===0) return filteredStatusArray.value=[]
      filteredStatusArray.value = JSON.parse(JSON.stringify(logsData.value))
      //排序
      filteredStatusArray.value.sort((a,b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
      // 如果不visible, 只取前几个
      if (!visible.value) filteredStatusArray.value = filteredStatusArray.value.slice(0, props.quantity)
    }, {immediate: true})
    
    const fetchLogs = ()=>{
      if (props.data && props.data.length>0) {
        logsData.value = props.data
      } else {
        if (!props.modelId ||!props.model) return
        store.dispatch('log/fetchLogs', {model: props.model, modelId:props.modelId})
          .then(response => {
            logsData.value = response.data.logs
          }).catch(e=>{
            console.log(e)
          })
      }
    }

    const addLog = ()=>{
      loading.value = true
      const log = {
        model: props.model,
        modelId: props.modelId,
        details: details.value
      }
      store.dispatch('log/addLog', log)
        .then((response) => {
          loading.value = false
          logsData.value = response.data.logs
          details.value = ''
          toast({component: ToastificationContent,
            props: {
              title: i18n('message.msg_added'),
              icon: 'CheckIcon',
              variant: 'success'
            }})
        }).catch(e =>{
          loading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: i18n('message.sth_wrong'),
              icon: 'AlertTriangleIcon',
              text: e.message,
              variant: 'danger'
            }})
        })
    }

    const deleteLog = (logId) => {
      store.dispatch('log/deleteLog', {
        logId, 
        model: props.model,
        modelId: props.modelId
      }).then(() => {
        logsData.value = logsData.value.filter(l=>l._id !== logId)
        loading.value = false
        toast({component: ToastificationContent,
          props: {
            title: i18n('message.msg_deleted'),
            icon: 'CheckIcon',
            variant: 'success'
            }})
        }).catch(e =>{ 
          loading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: i18n('message.sth_wrong'),
              icon: 'AlertTriangleIcon',
              text: e.message,
              variant: 'danger'
            }})
        })
    }

    fetchLogs()
    
    return {
      visible,
      details,
      loading,
      logsData,
      filteredStatusArray,

      addLog,
      deleteLog,
      fileUrl,
      capitalize,

      isToday
    }
  }
}
</script>

<style lang="scss">
#status-timeline{
  min-width:250px;
 .fade-enter-active {
    transition: all .2s ease;
  }
}
.z-index-1{
  z-index: 1;
}
</style>