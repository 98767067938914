<template>
  <q-dialog id="field-dialog" v-model="isDialogOpen" @hide="cancel">
    <div>
      <q-form class="bg-white rounded-lg" @submit="confirm">
        <div class="p-2">
          <h5 class="text-capitalize mb-2 text-primary text-capitalize">{{labelData}}</h5>
          <!-- <div v-for="(item,i) in fieldData">
            <q-input :autofocus="i===0" dense hide-bottom-space v-model="item.name" :rules="[val=>Boolean(val)||$t('rule.required')]" :label="$t(`language.${item.locale}._`)"></q-input>
          </div> -->

          <q-select v-if="isArray" v-model="fieldData" outlined new-value-mode="add-unique" multiple hide-dropdown-icon use-input dense :placeholder="$t('message.enter_add_new')" class="flex-even m-0">
            <template v-if="iconData" #prepend><fa-icon :icon="iconData" fixed-width class="text-primary mr-25"/></template>
            <template #selected-item="scope">
              <q-chip dense removable class="mr-50" @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex" color="light-primary" text-color="primary">{{scope.opt}}</q-chip>
            </template>
          </q-select>
          
          <q-input v-else autofocus dense hide-bottom-space v-model="fieldData" :rules="[val=>Boolean(val)||$t('rule.required')]" :label="labelData||''"></q-input>
        </div>
        <div class="d-flex justify-content-between px-2 py-1 shadow-top bg-light-secondary gap-2 mt-2">
          <q-btn type="submit" push no-caps color="primary" class="text-capitalize" :label="$t('action.save')"/>
          <q-btn v-if="showClearButton" no-caps push color="warning" class="text-capitalize" @click.stop="clear" :label="$t('action.clear')"/>
          <q-btn push color="danger" no-caps v-close-popup class="text-capitalize" :label="$t('action.cancel')"/>
        </div>
      </q-form>
    </div>
  </q-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  name:'field-dialog',
  props:{
    showClearButton:{
      type: Boolean,
      default: false
    }
  },
  setup(){
    const fieldData = ref([])
    const labelData = ref([])
    const isDialogOpen = ref(false)
    const isArray = ref(false)
    const iconData = ref(false)
    
    class FieldDialog {
      constructor (){}
      confirm(){}
      clear(){}
      cancel(){}
      resetDialog(){
        labelData.value = null
        return fieldData.value = isArray.value?[]:null
      }

      openDialog({data, label, icon}) {
        isDialogOpen.value=true
        fieldData.value = data
        labelData.value = label
        isArray.value = Array.isArray(data)
        iconData.value = icon

        return new Promise((res, rej)=>{
          this.confirm = ()=>{
            isDialogOpen.value=false
            res(fieldData.value)
            this.resetDialog()
          }
          this.clear = ()=>{
            isDialogOpen.value=false
            res(this.resetDialog())
            this.resetDialog()
          }
          this.cancel = () => {
            isDialogOpen.value=false
            res(false)
            this.resetDialog()
          }
        })
      }
    }

    const fieldDialog = new FieldDialog()

    return {
      isDialogOpen,
      fieldData,
      labelData,
      isArray,
      iconData,
      openDialog: fieldDialog.openDialog,
      confirm: fieldDialog.confirm,
      clear: fieldDialog.clear,
      cancel: fieldDialog.cancel,
      resetDialog: fieldDialog.resetDialog
    }
  }
}
</script>

<style lang="scss">
#field-dialog {
  .q-field__input.col {
    min-height: 100%;
  }
}
</style>