import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { i18n, toNumber } from '@core/utils/filter'
import saleStoreModule from './saleStoreModule'
if (!store.hasModule('sale')) store.registerModule('sale', saleStoreModule)
import { swal, Dialog } from '@core/utils/other'

export default function useSaleList() {
  const loading = ref(false)

  // Sale edit
  const emptySale = ()=> ({
    orderedAt: (new Date).toLocaleDateString(undefined,{ year: "numeric", month: "2-digit", day: "2-digit"}),
    toDelivery: false,
    address:null,
    billing:null,
    variants:[],
    tags:[],
  })

  const saleData = ref(emptySale())
  const resetSale = ()=> saleData.value = emptySale()

  // 计算DR/CR平衡
  const total = ref({dr:0, cr:0})
  const balanceDifference = () => Math.abs(total.value.dr - total.value.cr)
  watch(()=>saleData.value.variants, val => {
    total.value = {dr:0, cr:0} // reset
    if (!val || val.length===0) return
    val.filter(item=>!item.isJournaled).forEach(item => {
      if (item.drcr) total.value[item.drcr] += item.amount
    })
    total.value.dr = toNumber(total.value.dr)
    total.value.cr = toNumber(total.value.cr)
  }, {deep: true})


  // Fetch a Sale 新增 / 修改
  const fetchSale = (id, callback, c) => {
    if(!id) return
    store.dispatch('sale/fetchSale', id).then(response => {
      saleData.value = response.sale
      // saleData.value.entries.sort((a,b)=>a.date-b.date)
      // saleData.value.customerTrans.sort((a,b)=>b.levelDif-a.levelDif)
      saleData.value.orderedAt = new Date(saleData.value.orderedAt).toLocaleDateString(undefined, {year: "numeric", month: "2-digit", day: "2-digit"})
      if(callback) callback(response.sale)
    }).catch(() => {
      resetSale()
      if(c) c()
    })
  }


// Approve
  const approveSale = async (id) => {
    const confirm = await swal.fire({
      icon: 'question',
      title: i18n('message.if_confirm_approve'),
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: '<i class="fas fa-check fa-2x fa-fw"/>',
      denyButtonText: '<i class="fas fa-ban fa-2x fa-fw"/>',
      cancelButtonText: '<i class="fas fa-undo-alt fa-2x fa-fw"/>'
    })
    let approvalStatus
    if(confirm.isConfirmed) approvalStatus = 'approved'
    if(confirm.isDenied) approvalStatus = 'rejected'
    if (!approvalStatus) return

    store.dispatch('sale/approveSale', {id, approvalStatus}).then(() => {
      closeSaleSidebar()
      refetchData()
    }).catch(e=>{
      console.log(e);
      swal.fire({
        icon: 'error',
        title: e.message,
        showCancelButton: false
      })
    })
  }

// Delete
  const deleteSale = id => Dialog(true, 'danger').fire({
    title: i18n('message.if_confirm_delete'),
    confirmButtonText: i18n('action.delete'),
  }).then(v => {
    return new Promise(res=>{
      if(!v.value) return res(false)
      store.dispatch('purchase/deleteSale', id).then(()=>res(true)).catch(e=>{
        Dialog(false).fire({title: e.message})
        res(false)
      })
    })
  })

// Check in item
  const checkInItem = function(item){
    if(!item || !item._id) return
    swal.fire({
      icon:'question',
      showCancelButton: true,
      title: i18n('supply-chain.how_many'),
      input: 'range',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showLoaderOnConfirm: true,
      inputAttributes: {
        min: -item.fulfilledQuantity,
        max: item.balanceQuantity,
        step: 1
      },
      inputValue: item.balanceQuantity,
      preConfirm: (quantity) => {
        //validate
        if(quantity==0) return swal.showValidationMessage(i18n('rule.invalid_value'))

        return store.dispatch('sale/checkInItem', {id:item._id, quantity})
        .then(() => {
          refetchData(saleData.value._id)
        })
        .catch(error => {
          console.log(error);
          swal.showValidationMessage(`${i18n('rule.request_failed')}: ${error.message}`)
        })
      }
    })
  }

// Check in order
  const checkInOrder = function(id){
    if(!id) return
    swal.fire({
      icon:'question',
      showCancelButton: true,
      title: i18n('supply-chain.all_received'),
      showLoaderOnConfirm: true,
      preConfirm: () => store.dispatch('sale/checkInOrder', id)
        .then(() => refetchData())
        .catch(error => swal.showValidationMessage(`${i18n('rule.request_failed')}: ${error.message}`))
    })
  }

  return {
    loading,
    total, //计算DR CR平衡
    balanceDifference,

    saleData,
    resetSale,
    fetchSale,
    deleteSale,
    approveSale,
    deleteSale,

    // Check-in
    checkInItem,
    checkInOrder,
  }
}
