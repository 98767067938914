<template>
  <div id="tracking" class="w-100">
    <!-- New track -->
    <div class="p-1 d-flex justify-content-center">
      <q-form v-if="update" class="bg-white p-1 shadow-sm rounded-lg d-flex justify-content-between flex-wrap flex-sm-nowrap gap-2 flex-grow-1" style="max-width: 800px" @submit="addTrack">
        <!-- Time -->
        <q-input dense filled v-model="newTrack.time" :rules="[val=>Boolean(Date.parse(val))]" hide-bottom-space class="mb-0 flex-even">
          <template v-slot:prepend>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                <q-date v-model="newTrack.time" mask="YYYY-MM-DD HH:mm">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
          <template v-slot:append>
            <q-icon name="access_time" class="cursor-pointer">
              <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                <q-time v-model="newTrack.time" mask="YYYY-MM-DD HH:mm" format24h>
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-time>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>

        <q-select ref="statusRef" filled dense clearable popup-content-class="mt-1" :label="$t('form.status')" v-model="newTrack.status" :options="$global.order_status.filter(i=>i.sale)" class="d-inline-block mb-0 flex-even text-capitalize" >
          <template #option="scope">
            <q-item v-bind="scope.itemProps"  @click="newTrack.status = scope.opt.value;$refs.statusRef.hidePopup()">
              <q-item-section avatar>
                <q-icon :name="scope.opt.icon" :color="scope.opt.color" size="1.5rem" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-capitalize">{{ $t(`status.${scope.opt.label}`) }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <!-- Details -->
        <q-input ref="detailsRef" dense filled :loading="loading" v-model="newTrack.details" :rules="[val=>Boolean(val)]" lazy-rules hide-bottom-space :label="$t('form.details')" class="flex-even text-capitalize mb-0"/>
        <q-btn type="submit" push color="primary" :loading="loading"><fa-icon icon="plus" class="my-50" /></q-btn>
      </q-form>
    </div>

    <q-timeline v-if="trackData && trackData.length>0" layout="comfortable" side="right" class="p-2 bg-white shadow-sm mb-0" color="secondary">
      <q-timeline-entry v-for="track in trackData" :key="track._id" :color="(new Date(track.time)<=Date.now())?status(track.status,{from:$global.order_status, select:'color'})||'primary':''" 
        :icon="track.status | status({from:$global.order_status, select:'icon'})">
        <template v-slot:subtitle>
          <div :class="(new Date(track.time)<=Date.now())?'text-primary':''">{{track.time | dateString}}</div>
          <div>{{track.time | time}}</div>
        </template>
        <div class="d-flex justify-content-between gap-2">
          <div>
            <div v-if="track.status" class="h5 text-capitalize" :class="track.status | status({from:$global.order_status, select:'color', prefix:'text-'})">{{track.status | status({from:$global.order_status})}}</div>
            <div class="text-break" style="white-space: break-spaces">{{track.details}}</div>
          </div>
          <div class="d-flex align-items-center">
            <b-avatar v-if="track.user && track.user.name" v-b-tooltip.hover class="pull-up" :title="track.user.name" :src="fileUrl(track.user.avatar, 'user', true)" />
            <q-btn flat dense v-if="update && !track.client" class="p-1" @click.stop="deleteTrack(track._id)"><fa-icon class="text-danger" icon="times"/></q-btn>
          </div>
        </div>
      </q-timeline-entry>
    </q-timeline>

    
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { isToday } from '@core/utils/utils'
import { date, dateString, time, fileUrl, capitalize, status, i18n } from '@core/utils/filter'
import { Toast } from '@core/utils/other'

export default {
  name: 'tracking',
  filters: { date, dateString, time, status },
  props: {
    title: String,
    data:{
      type: Array,
      default:()=>[]
    },
    update: {
      type: Boolean,
      default: false
    },
    funcAdd: Function,
    funcRemove: Function
  },
  setup (props){
    const loading = ref(false)
    const trackData = ref([])
    const emptyTrack = ()=>({time:date(new Date(),true), status:'', details:''})
    const newTrack = ref(emptyTrack())
    const statusRef = ref(null)
    const detailsRef = ref(null)

    watch(()=>props.data, (val)=>{
      if (!val || val.length===0) return trackData.value = []
      trackData.value = JSON.parse(JSON.stringify(val)).sort((a,b) => Date.parse(b.time) - Date.parse(a.time)) // 排序
    }, {immediate: true})

    const reset = ()=>{
      loading.value = false
      newTrack.value = emptyTrack()
      detailsRef.value.resetValidation()
      
    }
    
    const addTrack = ()=>{
      loading.value = true
      if (props.funcAdd ) props.funcAdd(newTrack.value).then(reset).catch(e=>{
        Toast.fire({icon:'error', title: e.message})
        loading.value = false
      })
      return newTrack.value
    }

    const deleteTrack = id => {
      loading.value = true
      if (props.funcRemove ) props.funcRemove(id).then(reset).catch(e=>{
        Toast.fire({icon:'error', title: e.message})
        loading.value = true
      })
      return id
    }
    
    return {
      loading, status, statusRef, detailsRef,
      trackData,
      newTrack,
      reset,

      addTrack,
      deleteTrack,
      fileUrl,
      capitalize,

      isToday
    }
  }
}
</script>

<style lang="scss">
#tracking {
  min-width:250px;
 .fade-enter-active {
    transition: all .2s ease;
  }
  .q-timeline__title {
    margin-bottom: 0;
  }
  .q-timeline__subtitle {
    opacity: 2;
  }
  .q-timeline__dot [class*="fa-"].q-icon {
    transform: scale(0.6);
  }
}
</style>