<template>
  <div class="container rounded-lg border border-dark p-25 inner-shadow position-relative" >
    <div class="row my-75 text-bold text-center">
      <div class="col"></div>
      <div class="col-4">Account</div>
      <div class="col">Dr</div>
      <div class="col">Cr</div>
    </div>
    <hr class="my-25" />
    <div class="row my-75" v-for="item in transactions" :key="item._d">
      <div class="col"><BBadge class="mx-75 w-100 text-capitalize text-nowrap" :class="'bg-'+getColor('account', item.account.class)">{{item.account.name}}</BBadge></div>
      <div class="col-4"><!--Account-->
        <span :class="item._id === highlightId?'text-primary':''" class="text-capitalize">{{item.account.name}}</span>
      </div>
      <div class="dr col">
        <span v-if="item.account.drcr==='dr'" :class="item.amount<0?'text-danger':'text-success'">
        {{(item.amount >= 0)?'':'-'}} {{item.currency}} {{Math.abs(item.amount) | price}}
      </span>
      </div>
      
      <div class="cr col">
        <span v-if="item.account.drcr==='cr'" :class="item.amount<0?'text-danger':'text-success'">
          {{(item.amount >= 0)?'':'-'}} {{item.currency}} {{Math.abs(item.amount) | price}}
        </span>
      </div>
    </div>
    <!-- files -->
    <template v-if="entry && entry.files && entry.files.length>0">
      <hr class="my-25" />
      <FileGallery class="m-75" :files="entry.files" subFolder="entry" />
    </template>
    <!-- date -->
    <hr class="my-25" />
    <div v-if="entry" class="m-75 d-flex justify-content-between align-items-center">
      <div>{{entry.date | date(true)}}</div>
      <div>
        <q-btn v-if="showEditBtn&&$can('update', 'Finance')" push dense no-caps color="primary" :to="{name: 'finance-entry-edit', params: {entryId: entry._id}}" class="shadow-sm text-capitalize">
          <fa-icon icon="edit" class="mr-50"/>{{$t('action.edit')}}
        </q-btn>
      </div>
    </div>
    
    <q-inner-loading :showing="Boolean(!entry)">
      <q-spinner-gears size="50px" color="primary" />
    </q-inner-loading>
  </div>
</template>

<script>
import store from '@/store'
import { BBadge, BButton } from 'bootstrap-vue'
import { ref } from  '@vue/composition-api'
import { price, date, getColor } from '@core/utils/filter'

import accountStoreModule from '@/views/finance/account/accountStoreModule'
import FileGallery from '@core/components/file-gallery/FileGallery'
export default {
  name: 'account-sidebar-entry',
  props: {
    entryId: String,
    highlightId: String,
    showEditBtn:{
      type: Boolean,
      default:false
    }
  },
  filters: {
    price, date
  },
  components:{
    BBadge, BButton,
    FileGallery
  },
  setup(props){
    if (!store.hasModule('account')) store.registerModule('account', accountStoreModule)
    const entry = ref(null)
    const drs = ref([])
    const crs = ref([])
    const transactions = ref([])

    store.dispatch('account/fetchEntry', props.entryId).then(response => {
      entry.value = response.entry
      transactions.value = entry.value.transactions
    })

    return {
      entry,
      drs, crs, transactions,
      getColor
    }
  }
}
</script>