<template>
<div>
  <component :is="isPage?'div':'BSidebar'" id="variant-view" right sidebar-class="sidebar-lg" shadow backdrop :width="sidebarWidth" header-class="p-0" bg-variant="white"
    :visible="isSidebarActive" @hidden="close()" class="rounded-lg overflow-hidden shadow-sm">
    <template v-slot:header>
      <div class="w-100">
        <div class="w-100 d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 bg-white border-bottom shadow-sm z-index-10">
          <div v-if="variantData" class="flex gap-3 align-items-center text-primary">
            <fa-icon :icon="['fad', $t('supply-chain.variant.icon')]" size="2x"/>
            <div class="flex flex-column">
              <span class="text-capitalize">{{$t('supply-chain.variant._')}}</span>
              <span class="m-0 h5">{{variantData.names | name}}</span>
            </div>
            <q-btn flat padding="5px" :to="{name: 'variant-view', params:{variantId: variantData._id}}"><fa-icon icon="expand"/></q-btn>
          </div>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="close"/>
        </div>
        <q-tabs v-model="tab" class="bg-light-secondary inner-shadow" active-color="primary" indicator-color="primary" align="justify" narrow-indicator>
          <q-tab name="details" icon="fas fa-info-circle" :label="$t('form.details') | capitalize" class="pt-50 " content-class="gap-50 mb-25" />
          <q-tab name="history" icon="fas fa-truck" :label="$t('form.history') | capitalize" class="pt-50" content-class="gap-50 mb-25"/>
        </q-tabs>
      </div>
    </template>

    <q-tabs v-if="isPage" v-model="tab" class="bg-light-secondary" active-color="primary" indicator-color="primary" align="justify" narrow-indicator>
      <q-tab name="details" icon="fas fa-info-circle" :label="$t('form.details') | capitalize" class="pt-50 " content-class="gap-50 mb-25" />
      <q-tab name="history" icon="fas fa-truck" :label="$t('form.history') | capitalize" class="pt-50" content-class="gap-50 mb-25"/>
    </q-tabs>
    <q-tab-panels v-model="tab" value="order" animated transition-prev="jump-left" transition-next="jump-right" class="h-100 bg-white">
      <q-tab-panel name="details" :class="{'bg-light-danger':variantData.isActive===false}" class="p-0">
        <div v-if="variantData">
          <Carousel :files="variantData.files" />
          <div class="flex flex-column flex-sm-row flex-wrap p-2 gap-3 shadow-top-sm">
            <!-- html -->
            <div v-if="decodeURIComponent(variantData.html||'')" class="flex-size-6">
              <q-btn outline @click="isHtmlDialogOpen=true" label="html" color="primary" />
              <q-dialog v-model="isHtmlDialogOpen" position="right" auto-close>
                <div v-html="decodeURIComponent(variantData.html||'')" class="html-dialog bg-white overflow-auto p-1"/>
              </q-dialog>
            </div>
            <!-- Name -->
            <div class="flex-even flex-size-4 flex gap-2">
              <fa-icon :icon="['fad','font-case']" fixed-width class="mt-50 text-primary text-size-150"/>
              <div>
                <label class="text-primary d-block m-0">{{$t('form.name')|capitalize}}</label>
                <q-chip v-if="variantData.productData" size="sm" color="light-primary" text-color="white" class="px-0">
                  <q-chip v-if="variantData.productData" size="sm" color="primary" text-color="white">{{variantData.productData.names | name}}</q-chip>
                  <span class="mx-50 h5 mb-0 text-primary">{{variantData.names | name}}</span>
                </q-chip>
              </div>
            </div>
            <!-- SKU -->
            <div class="flex-even flex-size-4 flex gap-2">
              <fa-icon :icon="['fad','barcode-read']" fixed-width class="mt-50 text-primary text-size-150"/>
              <div>
                <label class="text-primary d-block m-0">{{$t('form.sku')|capitalize}}</label>
                <div class="flex gap-2 align-items-center">
                  <span>{{variantData.sku}}</span>
                </div>
              </div>
            </div>
            <!-- Price -->
            <div class="flex-even flex-size-4 flex gap-2">
              <fa-icon :icon="['fad','sack-dollar']" fixed-width class="mt-50 text-primary text-size-150"/>
              <div>
                <label class="text-primary d-block m-0">{{$t('form.price')|capitalize}}</label>
                <div v-if="variantData.marketPrice" class="text-strike text-warning">{{variantData.price | price($t(`currency.${variantData.currency}.sign`))}}</div>
                <span>{{variantData.price | price($t(`currency.${variantData.currency}.sign`))}}</span>
              </div>
            </div>
            <!-- Account -->
            <div class="flex-even flex-size-4 flex gap-2">
              <fa-icon :icon="['fad','sack-dollar']" fixed-width class="mt-50 text-primary text-size-150"/>
              <div>
                <label class="text-primary d-block m-0">{{$t('finance.account')|capitalize}}</label>
                <q-chip v-if="variantData.productData && variantData.productData.account" size="sm" :color="$global.account_color[variantData.productData.account.class]" text-color="white">
                  {{variantData.productData.account.names | name}}
                </q-chip>
              </div>
            </div>
            <!-- Size -->
            <div v-if="variantData.productData && variantData.productData.isInventory && volume(variantData.size)" class="flex-even flex-size-4 flex gap-2">
              <fa-icon :icon="['fad','cube']" fixed-width class="mt-50 text-primary text-size-150"/>
              <div>
                <label class="text-primary d-block m-0">{{$t('form.size')|capitalize}}</label>
                <div>{{variantData.size[0]}} x {{variantData.size[1]}} x {{variantData.size[2]}} {{$global.default_size}}</div>
                <span>{{variantData.size | volume}} {{$global.default_volume}}</span>
              </div>
            </div>
            <!-- weight -->
            <div v-if="variantData.weight" class="flex-even flex-size-4 flex gap-2">
              <fa-icon :icon="['fad','weight-hanging']" fixed-width class="mt-50 text-primary text-size-150"/>
              <div>
                <label class="text-primary d-block m-0">{{$t('form.weight')|capitalize}}</label>
                <div>{{variantData.weight}} {{$global.default_weight}}</div>
              </div>
            </div>
            <!-- Description -->
            <div class="flex-even flex-size-6 d-flex gap-2">
              <fa-icon :icon="['fad','align-left']" fixed-width class="mt-50 text-primary text-size-150"/>
              <div>
                <label class="text-primary d-block m-0 text-wrap">{{$t('form.description')|capitalize}}</label>
                <span>{{variantData.description}}</span>
              </div>
            </div>
          </div>
        </div>
      </q-tab-panel>

      <q-tab-panel name="history" class="p-0">
        <div class="d-flex flex-column gap-3 p-2">
          <VChart ref="chartRef" class="chart p-2" :option="chartOption" autoresize/>
          <BTable class="position-relative text-center rounded-lg shadow mb-0" responsive show-empty hover primary-key="_id" sort-by="createdAt" sort-desc
            :items="variantData.inventories" :fields="[
              {key:'orderNo',label:$t('form.order_number')},
              {key:'quantity.change.in',label:'Δ ' + $t('status.in._')},
              {key:'quantity.change.onhand',label:'Δ ' + $t('status.onhand._')},
              {key:'quantity.change.out',label:'Δ ' + $t('status.out._')},
              {key:'createdAt',label:$t('form.date')}]">
            <template #empty>
              <fa-icon class="my-3 text-secondary" :icon="['fad','cauldron']" size="4x" />
              <h5>{{$t("table.noResult")}}</h5>
            </template>
            <!-- orderNo -->
            <template #cell(orderNo)="{ item }">
              <div class="d-flex justify-content-between align-items-center" @click.stop="saleSidebarRef.open(item.orderId)">
                <q-chip size="sm" color="primary" text-color="white">
                  <fa-icon :icon="item.orderName==='Sale'?'store':(item.orderName==='Purchase'?'shopping-cart':'')" class="mr-50"/>
                  {{item.orderNo}}
                </q-chip>
                <fa-icon icon="chevron-right" class="mx-50 text-primary"/>
              </div>
            </template>
            <!-- createdAt -->
            <template #cell(createdAt)="{ value }">
              {{value | date}}
            </template>
          </BTable>
        </div>
      </q-tab-panel>
    </q-tab-panels>
    <q-inner-loading :showing="loading">
      <q-spinner-gears size="50px" color="primary" />
    </q-inner-loading>
  </component>
  <SaleView ref="saleSidebarRef"/>
</div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import useVariant from './useVariant'
import { BSidebar, BTable } from 'bootstrap-vue'
import { capitalize, fileUrl, price, name, status, volume, i18n, date } from '@core/utils/filter'
import FileGallery from '@core/components/file-gallery/FileGallery'
import Carousel from '@core/components/Carousel'
import SaleView from '../sale/SaleView'
import { $themeColors } from '@themeConfig'

//EChart
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers"
import { LineChart } from "echarts/charts"
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components"
import VChart, { THEME_KEY } from "vue-echarts"

use([
  CanvasRenderer,
  TitleComponent,
  LegendComponent,
  TooltipComponent,
  GridComponent,
  LineChart
])

export default {
  name: 'variant-view',
  components: {
    BSidebar, BTable,
    Carousel, FileGallery,
    SaleView,
    VChart
  },
  props:{
    isPage: Boolean
  },
  filters:{
    capitalize, price, name, volume, date
  },
  provide: {
    [THEME_KEY]: "light"
  },
  setup(props) {
    const saleSidebarRef = ref(null)
    const { route, router } = useRouter()
    const {
      loading,
      variantData,
      fetchVariant
    } = useVariant()

    // Tabs
    const tab = ref('details')
    watch(tab, val=>{
      if (val==='details') sidebarWidth.value = "600px"
      if (val==='history') sidebarWidth.value = '800px'
    })

    // Chart
    const chartRef = ref(null)
    const refreshChart = ref(false)
    const chartOption = {
      tooltip: { trigger: 'axis' },
      legend: { data: [capitalize(i18n('status.in._')), capitalize(i18n('status.onhand._')), capitalize(i18n('status.out._'))] },
      grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis: { type: 'category', boundaryGap: false, data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] },
      yAxis: { type: 'value' },
      series: [{
        name: i18n('status.in._'), type: 'line', data: [0,0,0,0,0,0] }, {
        name: i18n('status.onhand._'), type: 'line', data: [0,0,0,0,0,0] }, {
        name: i18n('status.out._'), type: 'line', data: [0,0,0,0,0,0] 
      }]
    }

    const fetchProductCallback = r =>{
      loading.value = false
      // series
      if (r.series){
        chartRef.value
        chartOption.xAxis = { type: 'category', boundaryGap: false,
          data: r.series.x.map(d=>new Date(d).toLocaleDateString('zh',{year: "numeric", month: "2-digit", day: "2-digit"}).replace(/\//g, '-'))
        }
        chartOption.series = [
          { name: capitalize(i18n('status.in._')), type: 'line', smooth: true, color:$themeColors.success, data: r.series.in }, 
          { name: capitalize(i18n('status.onhand._')), type: 'line', smooth: true, color:$themeColors.primary, data: r.series.onhand }, 
          { name: capitalize(i18n('status.out._')), type: 'line', smooth: true, color:$themeColors.danger, data: r.series.out.map(v=>-v) }
        ]
      }
      refreshChart.value = true
    }

    // Page view
    if (props.isPage) {
      if (route.value.params.variantId) {
        loading.value = true
        fetchVariant(route.value.params.variantId, fetchProductCallback)
      } else {
        router.go(-1)
      }
    }

    // Sidebar view
    const sidebarWidth = ref('600px')
    const isSidebarActive = ref(false)
    let isUpdated = false
    let closeAction = () => isSidebarActive.value = false
    const close = () => closeAction()
    const open = async id => {
      return new Promise(res=>{
        if(!id) return res(false)
        loading.value = true
        isSidebarActive.value = true
        fetchVariant(id, r => {
          fetchProductCallback(r) //本页更新
          closeAction = () => {
            res(isUpdated)
            isSidebarActive.value = false
          }
        })
      })
    }

    // HTML
    const isHtmlDialogOpen = ref(false)

    // 更新 Chart 视图
    watch([chartRef, refreshChart], ()=>{
      if (chartRef.value && refreshChart.value===true) {
        chartRef.value.setOption(chartOption)
        refreshChart.value = false
      }
    })

    return {
      loading, fileUrl, status, volume, capitalize,
      saleSidebarRef,

      // Sidebar
      isSidebarActive,
      open,
      close,

      // Tabs
      tab,
      sidebarWidth,

      variantData,
      fetchVariant,
      
      // Echart
      chartRef,
      chartOption,

      // Dialog
      isHtmlDialogOpen
    }
  }
}
</script>

<style lang="scss">
#variant-view {
  transition: all 300ms;
  //Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
  .b-sidebar-body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .text-size-150 {
    font-size: 1.5rem
  }
  .z-index-10 {
    z-index: 10
  }
}
</style>

<style lang="scss" scoped>
.chart {
  height: 500px;
}
.html-dialog {
  min-height:20rem;
  min-width: 93vw;
  @media (min-width:600px){
    min-width:40rem
  }
}
</style>