import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLogs(ctx, {model, modelId}){
      return new Promise((resolve, reject) => {
        if (!model || !modelId) return reject (new Error('No model or ID to fetch logs'))
        axios.get(`${model.toLowerCase()}/${modelId}/log`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    addLog(ctx, logData) {
      return new Promise((resolve, reject) => {
        if (!logData || !logData.details) return reject (new Error('At least write something'))
        if (!logData.model ||!logData.modelId) return reject (new Error('Unknown model or ID'))
        axios
          .post(`${logData.model.toLowerCase()}/${logData.modelId}/log`, { details: logData.details })
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    deleteLog(ctx, {logId, model, modelId}){
      return new Promise((resolve, reject) => {
        if (!logId ||!model ||!modelId) return reject (new Error('Incomplete params'))
        axios
          .delete(`${model.toLowerCase()}/${modelId}/log/${logId}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    }
  }
}
