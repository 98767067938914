<template>
  <q-carousel v-if="files && files.length>0" v-model="slide" swipeable animated thumbnails infinite :height="height">
    <q-carousel-slide v-for="(file, i) in files" :key="file.url" :name="i" :img-src="fileUrl(file.url)" />
  </q-carousel>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { fileUrl } from '@core/utils/filter'
export default {
  name: 'carousel',
  props: {
    files: Array,
    height: {
      type: String,
      default: '16rem'
    }
  },
  setup(props){
    const slide = ref(0)
    watch(()=>props.files, ()=>slide.value=0)
    return {
      fileUrl,
      slide
    }
  }
}
</script>